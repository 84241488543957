import React from "react";

const BrandLogos = ({ logos }) => {
  return (
    <div className="hidden sm:block">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-base font-semibold text-gray-500 uppercase">
          Our Pressure Area Care Solutions
        </p>
        <div className="mt-6 grid grid-cols-2 gap-2 sm:gap-4 lg:grid-cols-3 items-center text-center justify-center place-items-center m-auto">
          {logos.map((logo, index) => (
            <div key={index}>
              <a href={logo.link}>
                <img src={logo.image.url} alt={logo.id} className="h-14 " />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandLogos;
